/* .ant-select-selection-placeholder {
    color: #fff !important;
}

.ant-picker-input input::placeholder {
    color: #fff !important;
} */
.ant-modal-body .ant-input,.ant-modal-body .ant-select-selector,.cus-antd-white{
    background: #fff !important;
    color: #000 !important;
}
.ant-modal-body .ant-form-item .ant-form-item-label>label {
    color: #000;
}