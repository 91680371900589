/* Common */

body {
    margin: 0;
    background-color: #3d4043;
    color: #c5c7c6;
    font-family: 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    font-size: 12px;
}

.component-container {
    padding: 5px 10px 25px 10px;
}

.ul-messageIndexes {
    position: absolute;
    left: 0;
    right: 0;
    list-style: none;
    padding: 0;
    margin: 0;
    max-height: 116px;
    overflow: auto;
    z-index: 1;
    background: #53575a;
    color: #fff;
    border-top: 1px solid #3d4043;
    /* margin-top: 10px; */
    border-radius: 5px;
    top: 0;
}

.ul-messageIndexes li {
    padding: 8px;
    cursor: pointer;
    border-bottom: 1px solid #ccc;
}

.ul-suggestion-close {
    position: absolute;
    right: 16px;
    z-index: 9;
    background: #53575a;
    top: 0;
    border: 0;
    font-size: large;
    padding: 0px 5px;
}

/* Form style */

.ant-form-item {
    margin-bottom: 8px;
}

.cus_lable label {
    color: #c5c7c6 !important;
}

.form-input-header {
    font-size: 12px;
    font-weight: bold;
}

.ant-input,
.ant-select-selector,
.ant-input-number,
.ant-input-number-input {
    box-shadow: none !important;
    background: #53575a !important;
    border: 1px solid #53575a !important;
    height: 28px;
    color: #fff !important;
    font-size: 14px;
}

/* Button style */
.cus-btn-action {
    height: 28px;
    font-size: 11px;
    box-shadow: none !important;
    background: #53575a !important;
    border: 1px solid #53575a;
    color: #fff !important;
}

.cus-btn-fixed-top {
    position: sticky;
    z-index: 1;
    width: 100%;
    background-color: rgb(61, 64, 67);
    padding: 5px 0;
    top: 0;
    left: 0;
}

/* Table Style */
.ant-table-body {
    overflow: auto !important;
}

.cus-table table {
    background: #53575a;
    /* border: 1px solid #53575a; */
    font-size: 12px;
}

.cus-table table .ant-table-thead .ant-table-cell {
    background: #53575a !important;
    color: #fff !important;
    border: 1px solid #3D4043 !important;
    padding: 5px;
}

.cus-table .ant-table-thead>tr>th::before {
    background: transparent !important;
}

.cus-table table .ant-table-tbody .ant-table-cell {
    background: #3d4043 !important;
    color: #fff !important;
    border: 1px solid #53575a !important;
    padding: 0 5px;
}

/* antd Drawer */
.ant-drawer-content {
    background-color: #3d4043 !important;
}

.ant-drawer-header {
    background-color: #212529 !important;
    border-top: 3px solid #333;
}

.ant-drawer-title,
.ant-drawer .anticon {
    color: #fff !important;
}

.ant-drawer-content-wrapper {
    width: 60vw !important;
    height: calc(100vh - 41px);
}

.ant-drawer-footer {
    background-color: #fff;
}

/* antd Form */
.ant-form-item .ant-form-item-label>label {
    color: #c5c7c6;
    font-size: 12px;
}

.cus-ant-select {
    width: 100% !important;
}

/* Card */
.cus-card {
    background-color: transparent !important;
    border: none;
    font-size: inherit;
}

.cus-card .ant-card-head {
    background: #262626;
    color: #fff;
    border: none;
    padding-left: 10px;
    min-height: 40px;
}

.cus-card-title-icon {
    font-size: 12px;
    margin-right: 10px;
}

.cus-card .ant-card-body {
    padding: 10px 3px 0;
}

.ant-divider-dashed {
    margin: 13px 0;
    border: 1px solid #262626;
}

.ant-checkbox-wrapper {
    color: #fff;
    font-size: 12px;
}

/* PlaceHolder */
.ant-select-selection-placeholder {
    color: #fff !important;
}

.ant-picker-input input::placeholder {
    color: #fff !important;
}